.fab {
    width: 100%;
    position: fixed;
    z-index: 3;
    bottom: 0;
    transition: opacity 0.2s;
    display: flex;
    @include lg {
        width: 7.5rem;
        bottom: 50%;
        right: 0;
        transform: translateY(50%);
        display: block;
    }

    &--hidden {
        opacity: 0;
    }

    &--hasDisclaimer {
        bottom: 3.75rem;
        @include lg {
            bottom: 50%;
        }
    }
    &--hasSecondaryNav {
        @include lg {
            transform: translateY(70%);
        }
    }

    &__body {
        width: 100%;
        flex: 1;
        a {
            height: 100%;
        }
        @include lg {
            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }
        }
    }

    &__icon {
        line-height: normal;
        font-size: 1.25rem;

        @include lg {
            font-size: 2rem;
        }
        &--multiple {
            display: none;
            @include lg {
                display: block;
            }
        }
    }

    &__link {
        color: $color-white;
        padding: 0.75rem 1.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $spacer-3;
        text-align: center;
        border: none;
        height: 100%;
        transition: $transition-background;
        @include elevation-far;
        @include lg {
            padding: $spacer-4;
            border-top-left-radius: $border-radius-sm;
            border-bottom-left-radius: $border-radius-sm;
            flex-direction: column;
        }
        &--orange {
            background: $color-orange;
            &:hover {
                background-color: darken($color-orange, 10%);
            }
        }
        &--cyan {
            background: $color-cyan;
            &:hover {
                background-color: darken($color-cyan, 10%);
            }
        }
        &--slate {
            background: $color-slate;
            &:hover {
                background-color: darken($color-slate, 10%);
            }
        }
        &--royal {
            background: $color-royal;
            &:hover {
                background-color: darken($color-royal, 10%);
            }
        }
    }
    &__link-text {
        font-size: 1.125rem;
        line-height: 1.625rem;
        font-weight: 400;
        @include lg {
            font-size: 1.375rem;
            line-height: 2rem;
        }
    }
}
