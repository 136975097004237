.header {
    &--sticky {
        position: sticky;
        top: 0;
        z-index: 4;
    }
}

.navbar {
    background: var(--navbar-background);
    display: none;
    @include desktop-navbar {
        display: block;
        height: $navbar-desktop-height;
    }

    &__container {
        @extend .container;
        justify-content: flex-end;
        position: relative;
        display: flex;
        height: 100%;
        align-items: center;
        &--no-logo {
            justify-content: flex-start;
            .navbar__nav {
                flex: 1;
            }
            .navbar__list {
                justify-content: space-between;
            }
            .navbar__item {
                margin: 0 !important;
            }
        }
    }

    &__brand {
        position: absolute;
        top: $spacer-5;
        left: 0.875rem;
        z-index: 1;
        display: flex;
    }

    &__list {
        visibility: visible;
        display: flex;
        align-items: center;
        list-style: none;
    }

    &__item {
        display: block;

        &:not(:last-child) {
            margin-right: $spacer-6;
        }

        &--lg {
            margin-right: $spacer-12;
            .navbar__link {
                &--20 {
                    margin-bottom: 0;
                    font-weight: $font-weight-regular;
                    font-size: var(--navbar-dropdown-font-size);
                    @include focus;
                }
                &--16 {
                    margin-bottom: 0;
                    font-weight: $font-weight-regular;
                    font-size: 1rem;
                    @include focus;
                }
            }
        }
    }

    &__dropdown {
        display: grid;
        position: absolute;
        top: var(--navbar-dropdown-offset-top);
        left: 0;
        width: 100%;
        padding: $spacer-4;
        list-style: none;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;
        border-radius: $border-radius-lg;
        transform: translateY(60px);
        background: $color-white;
        grid-gap: 1.5rem;
        z-index: 4;
        @include elevation-far;
        &--open {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }

        &--simple {
            text-align: center;
        }

        &--card {
            .navbar__dropdown-link {
                text-align: center;
                background: #f7f7f7;
                border-radius: $border-radius-lg;
                padding: $spacer-6 $spacer-4;
                transition: all 0.2s;
                height: 100%;
                cursor: pointer;
                &:hover {
                    background: #f3f3f3;
                }
            }
        }

        &--basic {
            top: 5.3125rem;
            width: 17.5rem;
            left: auto;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            transform: translateX(-30px);
            padding: $spacer-6;

            & > li {
                & > a {
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &__dropdown-link {
        * {
            transition: 0.5s;
        }
        &:hover {
            .navbar__icon--normal {
                opacity: 1;
            }
            .navbar__icon--luminosity {
                opacity: 0;
            }
            .navbar__dropdown-btn {
                border-color: $color-maroon;
                color: $color-maroon;
            }
            .navbar__title,
            .navbar__title * {
                color: $color-black;
            }
        }
    }

    &__dropdown-btn {
        font-size: $font-size-x-small;
    }

    &__dropdown-arrow {
        display: var(--navbar-dropdown-arrow-display);
        transition: $transition-default;
        transform: rotate(0deg);
        color: $color-grey-5;
        font-size: $font-size-body;
        &--active {
            transform: rotate(180deg);
        }
    }

    &__link {
        &--20 {
            font-size: 1.25rem;
            color: $color-white;
            text-decoration: none;
            display: inline-flex;
            gap: $spacer-2;
            align-items: center;
            position: relative;
            background: none;
            border: none;
            text-align: left;
            line-height: normal;
            margin-bottom: 0;

            * {
                color: $color-white;
                font-size: 1.25rem;
            }

            &:hover:not(&--active) {
                opacity: 0.8;
            }

            @include focus;
            &--active {
                &::after {
                    content: '';
                    height: 2px;
                    width: 100%;
                    background: $color-white;
                    position: absolute;
                    bottom: -$spacer-2;
                    left: 0;
                }
            }
        }
        &--16 {
            font-size: 1rem;
            color: $color-white;
            text-decoration: none;
            display: inline-flex;
            gap: $spacer-2;
            align-items: center;
            position: relative;
            background: none;
            border: none;
            text-align: left;
            line-height: normal;
            margin-bottom: 0;

            * {
                color: $color-white;
                font-size: 1rem;
            }

            &:hover:not(&--active) {
                opacity: 0.8;
            }

            @include focus;
            &--active {
                &::after {
                    content: '';
                    height: 2px;
                    width: 100%;
                    background: $color-white;
                    position: absolute;
                    bottom: -$spacer-2;
                    left: 0;
                }
            }
        }
    }

    &__category-list {
        list-style: none;
        &--split {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__category-item {
        line-height: normal;

        &:not(:last-child) {
            margin-bottom: 1.25rem;
        }
    }

    &__category-link {
        text-decoration: none;
        display: inline;
        line-height: normal;
        position: relative;

        &--28 {
            line-height: $line-height-h5;
        }

        &:hover {
            text-decoration: underline;
        }

        &:not(:last-child) {
            margin-bottom: 1.25rem;
        }
    }

    &__search {
        color: $color-white;
        font-size: var(--navbar-link-font-size);
        position: relative;
        line-height: normal;
        @include focus;
    }

    &__search-icon {
        width: 0.875rem;
        display: inline-flex;
        @include focus;

        &:hover {
            opacity: 0.8;
        }

        &--hidden {
            visibility: hidden;
        }
    }

    &__title,
    &__title * {
        font-weight: $font-weight-regular;
        line-height: $line-height-h5;
        color: $color-slate;
        @include nlc {
            margin-bottom: $spacer-4;
        }
    }

    &__description {
        font-size: $font-size-small;
        line-height: $line-height-small;
        color: $color-grey-60;
        margin-bottom: $spacer-6;
    }

    &__icon {
        margin-bottom: $spacer-4;
        height: $spacer-12;
        width: 100%;
        display: inline-block;
        position: relative;
        &--normal {
            mix-blend-mode: normal;
            opacity: 0;
        }
        &--luminosity {
            mix-blend-mode: luminosity;
            opacity: 1;
        }
    }

    &__category {
        &:not(:last-child) {
            margin-bottom: $spacer-10;
        }
    }
}

.mobile {
    height: $navbar-mobile-height;
    position: relative;
    background: var(--navbar-background);
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop-navbar {
        display: none;
    }
    &__nav {
        position: fixed;
        width: 100%;
        top: $navbar-mobile-height;
        padding: 1.25rem 1.25rem 2.5rem 1.25rem;
        height: calc(100% - $navbar-mobile-height);
        background: $color-white;
    }
    &__brand {
        display: flex;
    }

    &__search {
        position: relative;
        margin-bottom: $spacer-6;
    }

    &__list {
        width: 100%;
        overflow-y: auto;
        list-style: none;
        display: flex;
        flex-direction: column;
        height: calc(100% - $navbar-mobile-height);
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: $spacer-6;
        }
        &--util {
            display: flex;
            flex-direction: column;
            gap: $spacer-4;
            margin-top: auto;
        }
    }

    &__link {
        color: $color-black;
        font-size: $font-size-h4;
        border: none;
        background: none;
        text-decoration: none;

        &--lg {
            font-size: $font-size-h3;
            font-weight: $font-weight-bold;
        }

        &--link {
            color: #000;
        }
    }

    &__seperator {
        margin-top: 0;
        margin-bottom: $spacer-6;
    }

    &__back {
        border: none;
        background: none;
        color: var(--btn-primary);
        font-size: $font-size-mobile-body;
    }

    &__description {
        color: $color-grey-60;
        margin-bottom: $spacer-4;
    }

    &__sublist {
        list-style: none;
        margin-bottom: $spacer-24;
    }

    &__dropdown {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;

        svg {
            height: 20px;
            color: var(--btn-primary);
        }
    }

    &__subdropdown {
        color: $color-slate;
    }

    &__trigger {
        position: absolute;
        background: none;
        border: none;
        width: 60px;
        height: 61px;
        top: 0px;
        left: 0px;
        padding: 8px 0 0 14px;
        transition: all 0.2s;

        @include desktop-navbar {
            display: none;
        }

        &--active {
            background: $color-white;

            .mobile__trigger-line {
                background: var(--btn-primary);

                &--1 {
                    position: relative;
                    transform: rotate(45deg) translateY(2.1px) translateX(5.8px);
                    width: 18px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &--2 {
                    position: relative;
                    z-index: 3;
                    transform: rotate(-45deg);
                    width: 36px;
                }

                &--3 {
                    position: relative;
                    transform: rotate(45deg) translateY(-14.7px)
                        translateX(6.8px);
                    width: 18px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

    &__trigger-line {
        background: $color-white;
        height: 4px;
        border-radius: 2px;
        margin-bottom: 8px;
        transition: 0.4s all;
        width: 36px;
    }
}
