.testimonial {
    padding: $spacer-6 0 $spacer-6 $spacer-10;
    border-left: $spacer-1 solid $color-royal;
    quotes: '\201C''\201D';
    &--disable-quotes {
        quotes: '' '';
    }
    @include nlc {
        margin-bottom: $spacer-5;
    }
    &__quote {
        margin-bottom: $spacer-6;
        position: relative;
        h3,
        h4 {
            display: inline;
        }
        &--h3 {
            &::before {
                content: open-quote;
                font-size: $font-size-h3;
                position: absolute;
                line-height: normal;
                left: -14px;
                top: 4px;
            }
            &::after {
                content: close-quote;
                display: inline-block;
                font-size: $font-size-h3;
            }
        }
        &--h4 {
            &::before {
                content: open-quote;
                font-size: $font-size-h4;
                line-height: normal;
                position: absolute;
                left: -12px;
                top: 0px;
            }
            &::after {
                content: close-quote;
                display: inline-block;
                font-size: $font-size-h4;
            }
        }
    }
    &__cite-wrapper {
        display: flex;
        gap: $spacer-1;
        align-items: center;
    }
    &__cite {
        font-style: normal;
        display: inline-block;
    }
}
