$background-utilities: (
    'background-color': (
        'prefix': 'bg',
        'values': $backgrounds,
    ),
);

.bg-slate,
.bg-crimson,
.bg-encircle,
.bg-progress,
.bg-early-tavr,
.bg-clasp-ii-tr,
.bg-crimson-gradient {
    h2,
    h3,
    h4,
    h5,
    p,
    div,
    sup,
    .richtext a,
    .richtext.lead * {
        color: $color-white;
    }
    .richtext li::marker {
        color: $color-white;
    }
    .footnotes {
        color: $color-grey-5;
    }
    .testimonial {
        border-color: $color-white;
    }
}

@each $breakpoint, $size in $breakpoints {
    @if ($breakpoint == 'sm') {
        @each $property, $map in $background-utilities {
            $prefix: map-get($map, 'prefix');
            $values: map-get($map, 'values');
            @each $k, $v in $values {
                .#{$prefix}-#{$k} {
                    #{$property}: $v;
                }
                section.#{$prefix}-#{$k} + section.#{$prefix}-#{$k} {
                    padding-top: 0;
                    #{$property}: $v;
                }
                section.#{$prefix}-#{$k}
                    + section.#{$prefix}-#{$k}
                    > section.#{$prefix}-#{$k} {
                    // thank message blocks
                    padding-top: 0;
                    #{$property}: $v;
                }
            }
        }
    }
}

/*=== Gradient Backgrounds === */

@each $key, $val in $gradients {
    .bg-#{$key} {
        background: linear-gradient(
            278deg,
            darken($val, 10%) 0%,
            $val 52%,
            darken($val, 10%) 100%
        );
    }
}
