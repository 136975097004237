.disclaimer {
    &__trigger {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: $color-white;
        box-shadow: inset 0px 1px 0px $color-grey-20;
        padding-top: $spacer-4;
        padding-bottom: $spacer-4;
        opacity: 1;
        transition: $transition-default;
        z-index: 3;
        max-width: $wrapper;
        &--hidden {
            opacity: 0;
            z-index: -1;
        }
    }
    &__trigger-wrap {
        display: flex;
        align-items: center;
        @include container;
    }
    &__icon {
        color: $color-warning;
        margin-right: $spacer-2;
        &--lg {
            font-size: 1.4375rem;
            margin-right: $spacer-3;
        }
    }
    &__link {
        margin-bottom: 0;
        font-size: $font-size-small;
        color: $color-black;
        text-decoration: underline;
    }
    &__arrow {
        margin-left: $spacer-2;
        color: $color-black;
    }
    &__head {
        display: flex;
        align-items: center;
        margin-bottom: $spacer-4;
    }
    &__title {
        line-height: normal;
        margin-bottom: 0;
    }
    &__content {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    &__description {
        h4 {
            @include h5;
        }
        p {
            @include small;
        }
    }
    li::marker {
        color: $color-black !important;
    }
    ul {
        @include small;
    }
}

.bg-white + .disclaimer.bg-white .disclaimer__content,
.disclaimer.bg-white-grey-line .disclaimer__content {
    border-top: 1px solid $color-grey-20;
}
