.resource {
    border: none;
    text-align: left;
    text-decoration: none;
    background: none;
    @include focus;
    &:hover &,
    &:focus & {
        &__link {
            text-decoration: none;
        }
        &__arrow {
            animation: linkarrow 0.8s infinite;
            position: relative;
        }
    }
    &__head {
        margin-bottom: $spacer-6;
        display: flex;
        position: relative;
    }
    &__title {
        margin-bottom: $spacer-2;
    }
    &__video {
        height: 100%;
        width: 100%;
        box-shadow: $elevation-closest;
        border: 1px solid $color-grey-5;
        border-radius: $border-radius-lg;
        overflow: hidden;
    }
    &__image {
        height: 100%;
        width: 100%;
        box-shadow: $elevation-closest;
        border: 1px solid $color-grey-5;
        border-radius: $border-radius-lg;
        @include aspect-ratio(16, 9);
        * {
            border-radius: $border-radius-lg;
            background: none;
        }
    }
    &__body {
        padding: 0 $spacer-2;
    }
    &__description {
        margin-bottom: $spacer-6;
    }
    &__link {
        color: var(--btn-primary);
        font-size: $font-size-body;
        line-height: $line-height-body;
        text-decoration: underline;
    }
    &__arrow {
        color: var(--btn-primary);
        margin-left: $spacer-2;
    }
}
