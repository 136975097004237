.sn {
    cursor: pointer;
    transition: border 0.2s;
    z-index: 3;
    background: $color-white;
    top: 0;
    position: sticky;
    @include lg {
        border-bottom: 1px solid $color-grey-20;
    }
    @include lg {
        padding: $spacer-5 0;
        cursor: auto;
    }
    &--sticky-primary-nav {
        top: $navbar-mobile-height;
        @media (min-width: 1200px) {
            top: $navbar-desktop-height;
        }
    }
    &__container {
        @include container;
        @include lg {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            flex-direction: row;
        }
    }
    &__title {
        @include h5;
        margin-bottom: 0 !important;
        text-decoration: none;
        display: none;
        @include lg {
            display: inline-block;
        }
    }
    &__links {
        position: absolute;
        background-color: $color-white;
        top: calc(60.4px - $spacer-2);
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        box-shadow: 0px 8px 8px -4px rgba($color-black, 0.16);
        transition: $transition-default;
        @include lg {
            transition: 0s;
        }
        @include lg {
            box-shadow: none !important;
            max-height: none;
            width: auto;
            visibility: visible;
            opacity: 1;
            transition: 0s;
            display: block;
            position: relative;
            top: 0;
            left: 0;
        }
        &--active {
            opacity: 1;
            visibility: visible;
            top: 59.7px;
            @include lg {
                top: 0;
                display: block;
                width: auto;
            }
        }
    }
    &__link {
        padding: $spacer-2 $spacer-4;
        color: $color-grey-80;
        text-decoration: none;
        font-size: $font-size-x-small;
        border-bottom: 1px solid $color-grey-20;
        margin: 0 0.875rem;
        display: inline-block;
        @include lg {
            margin: 0;
            border-bottom: 0;
        }
        &:hover {
            text-decoration: underline;
        }
        &--active {
            color: var(--secondary-nav-active);
            &:hover {
                text-decoration: none;
            }
        }
        &:last-child {
            border-bottom: none;
            margin-bottom: $spacer-2;
            @include lg {
                margin-bottom: 0;
            }
        }
    }
    &__toggle {
        @include focus;
        @include container;
        background: $color-white;
        border: none;
        padding-top: $spacer-4;
        padding-bottom: $spacer-4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: $transition-default;
        border-bottom: 1px solid $color-grey-20;
        @include lg {
            display: none;
        }
        &--active {
            border-bottom: none;
            .sn__toggle-icon {
                rotate: 180deg;
            }
        }
    }
    &__toggle-title {
        @include h5;
        margin-bottom: 0 !important;
        text-decoration: none;
        display: inline-block;
        @include lg {
            display: none;
        }
    }
    &__toggle-icon {
        font-size: $spacer-5;
        color: $color-grey-80;
    }
}
